import React, { Component } from "react";
import { userService, invoiceService, networkErrorHelper, dateHelper, learningService, CourseHelper } from "libs";
import { NavLink, withRouter } from 'react-router-dom';
import { ROUTES } from "const";
import { CourseList } from "components/course";

import {
  Row, Col, Button,
  Callout, CalloutTitle, CalloutText, Icon
} from 'design-react-kit';

import {
  BoxDanger, BoxSuccess, ModalHandler,
  Title, MSection, MnemoLoading
} from "components/misc";

class CourseInvoices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      invoice: {invoice: [], creditNote: []},
      user: null,
      requestSuccess: false,
      rCode: (typeof this.props.match.params.rCode !== 'undefined') ? this.props.match.params.rCode : false
    };
  }

  componentDidMount() {
    if (this.state.rCode) {
      this.loadRemote(this.state.rCode);
    } else {
      this.setState({ loading: false });
    }
  }//componentDidMount


  loadRemote(rCode) {

    learningService
      .courseDashboard(rCode)
      .then(({ data }) => {

        const { course } = data.payload;

        // if unicamillus redirct to MU_PAYMENT
        if (CourseHelper.isUnicamillus(course.code) || CourseHelper.isIUL(course.code)) {
          window.location = ROUTES.MU_COURSE_PAYMENTS + '/' + rCode;
        }
      })
      .catch(error => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }       
      });

    //--- get & ser in State user data profile ---
    this.loadRemoteUserProfile();

    invoiceService.invoiceCourse(rCode)
      .then(({ data }) => {

        this.setState({
          invoice: data.payload,
          loading: false
        })

      })
      .catch(error => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({
          loading: false
        })
      })
  }//loadRemote

  //--- start: load user data profile ---
  loadRemoteUserProfile() {
    userService
      .me()
      .then(({ data }) => {
        this.setState({
          user: data.payload.user,
        })
      })
      .catch(error => {
        networkErrorHelper.notify(error);
      });
  }
  //--- end: load user data profile ---


  requestSendInvoice = (e, invoiceObj) => {
    e.stopPropagation();
    ModalHandler.show(
      invoiceObj,
      'conferma richiesta',
      `Ti invieremo per email la fattura n ${invoiceObj.inumber} del ${dateHelper.toITDate(invoiceObj.idate)} 
         all'indirizzo ${this.state.user.email}. Confermi?`,
      null,
      this.confirmSendRequest(invoiceObj, 'invoice')
    );
  }//requestSendInvoice


  requestSendCreditNote = (e, creditNoteObj) => {
    e.stopPropagation();
    ModalHandler.show(
      creditNoteObj,
      'conferma richiesta',
      `Ti invieremo per email la nota di credito n ${creditNoteObj.inumber} del ${dateHelper.toITDate(creditNoteObj.idate)} 
         all'indirizzo ${this.state.user.email}. Confermi?`,
      null,
      this.confirmSendRequest(creditNoteObj, 'creditNote')
    );
  }//requestSendCreditNote


  confirmSendRequest = (dataObj, type) => {
    let payload = {
      id: dataObj.id,
      rCode: dataObj.registrationCode,
      type
    };

    this.setState({
      loading: true
    })    

    //--- ---

    invoiceService.requestSendInvoice(payload).then(({ data }) => {
      this.setState({
        loading: false,
        requestSuccess: (data.status === 'OK') ? true : false,
      })

      setTimeout(() => {
        this.setState({
          loading: false,
          requestSuccess: false,
        })
      }, 8000);
    })
    .catch(error => {
      console.log(error);
      if (!networkErrorHelper.is404(error)) {
        networkErrorHelper.notify(error);
      }
    });
  }//confirmSendRequest

  //--- ---

  render() {

    const { loading, rCode, invoice, user, requestSuccess } = this.state;

    if (loading) {
      return <MnemoLoading />;
    }

    return (
      <>
        {(rCode) ? (
          <>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Le mie fatture</Title>
            {(user && requestSuccess) && <BoxSuccess>Richiesta inviata con successo.<br />A breve riceverai la fattura alla casella {user.email}</BoxSuccess>}

            {
              (invoice.invoice.length > 0) ? (
                <>
                  <MSection>
                    {invoice.invoice.map((item, i) => (
                      <Row key={`invoice-${i}`}>
                        <Col size="12">
                          <Callout className="mnemo-callout-warning my-2 p-3" highlight tag="div">
                            <CalloutTitle tag="h6">
                              {`Fattura N. ${item.inumber} del ${dateHelper.toITDate(item.idate)} importo euro ${item.total}`}
                            </CalloutTitle>
                            <CalloutText>
                              {item.idescription}
                              <hr />
                              <Row className="p-0 m-0">
                                <Button color="primary" onClick={(e) => this.requestSendInvoice(e, item)}>Ricevi Fattura</Button>
                              </Row>
                            </CalloutText>
                          </Callout>
                        </Col>
                      </Row>
                    ))}
                  </MSection>
                </>
              ) : (
                <BoxDanger>Attualmente non sono state emesse fatture per questo Corso.</BoxDanger>
              )
            }

            {
              (invoice.creditNote.length > 0) && (
                <>
                  <MSection>
                    {invoice.creditNote.map((item, i) => (
                      <Row key={`creditNote-${i}`}>
                        <Col size="12">
                          <Callout className="mnemo-callout-warning my-2 p-3" highlight tag="div">
                            <CalloutTitle tag="h6">
                              {`Nota di Credito N. ${item.inumber} del ${dateHelper.toITDate(item.idate)} importo euro ${item.total}`}
                            </CalloutTitle>
                            <CalloutText>
                              {item.idescription}
                              <hr />
                              <Row className="p-0 m-0">
                                <Button color="primary" onClick={(e) => this.requestSendCreditNote(e, item)}>Invia Nota di Credito</Button>
                              </Row>
                            </CalloutText>
                          </Callout>
                        </Col>
                      </Row>
                    ))}
                  </MSection>
                </>
              ) 
            }

          </>
        ) : (
          <>
            <Title>Le mie fatture</Title>
            <MSection className="my-2">Seleziona il corso a cui la richiesta si riferisce</MSection>
            <CourseList actionLink={ROUTES.COURSE_INVOICES}></CourseList>
          </>
        )}
      </>
    )
  }
}

export default withRouter(CourseInvoices);