import React, { Component } from "react";
import { ROUTES } from "const";
import { withRouter, NavLink } from "react-router-dom";
import { Icon } from "design-react-kit";
import {
    BoxDanger,
    MnemoLoading,
    MSection,
    SupportoTecnico,
    Title
} from "components/misc";

import { CourseHelper, courseService, networkErrorHelper } from "libs";
import { MTable } from "components/table";

class CourseTitleSelfDeclaration extends Component {

    state = {
        loading: true,
        loadingError: false,
        rCode: '',
        courseModules: [],
        course: [],
        courseYear: parseInt(process.env.REACT_APP_AA_YEAR)
    };

    tableConfig = {
        columns: [
            {
                id: 'title',
                Header: 'Insegnamento',
                accessor: 'title',
            },
            {
                id: 'ssd',
                Header: 'SSD',
                accessor: 'ssd',
            },
            {
                id: 'cfu',
                Header: 'CFU',
                accessor: 'cfu',
            },
            {
                id: 'date',
                Header: 'Data',
                accessor: 'date',
            },
            {
                id: 'vote',
                Header: 'Votazione',
                accessor: 'vote',
            }
        ],
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        courseService.myCourseModules(rCode)
            .then(({ data }) => {

                const courseYear = parseInt(data.payload[0].courseCode.slice(-2));
                this.setState({
                    rCode,
                    courseModules: data.payload,
                    loading: false,
                    courseYear
                });


            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });
    }

    showOrganizationDescription() {

        let $organization = '';
        if (CourseHelper.isUnicamillus(this.state.courseModules[0].courseCode)) {
            $organization = "Università: Università Saint Camillus International University of Health Sciences";
        } else if (CourseHelper.isIUL(this.state.courseModules[0].code)) {
            $organization = "Università: Università Telematica degli Studi IUL";
        }else {
            $organization = "Università: Università per Stranieri Dante Alighieri di Reggio Calabria";
        }

        return $organization;
    }

    render() {

        const { loading, loadingError, rCode, courseModules, courseYear } = this.state;

        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (<>
            {(loading) && (<MnemoLoading />)}
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Piano di Studi</Title>
            {(courseModules.length > 0) && (
                <MSection className="m-3">
                    <h5>{(courseModules.length > 0) && this.showOrganizationDescription()} </h5>
                    <hr />
                    <MTable tableData={courseModules} tableConfig={this.tableConfig} />
                    {(courseYear === 22) && (<b><p>Tale piano di studi verrà certificato solo ed esclusivamente al superamento dell'esame finale, in mancanza di ciò non verrà rilasciato alcun certificato.</p></b>)}

                    {(courseYear === 23) && (<b><p>Tale piano di studi verrà certificato solo ed esclusivamente al superamento dell'esame finale, in mancanza di ciò non verrà rilasciato alcun certificato.</p></b>)}

                </MSection>
            )}
        </>)
    }
}

export default withRouter(CourseTitleSelfDeclaration);
