import { proxyBE, proxyApiMnemo } from "./common";
import { mnemoService } from "libs/api";

export const adminService = {
  course(payload) {
    return proxyBE.get(`admin/course/${JSON.stringify(payload)}`);
  },
  loadAllCourse(payload) {
    return proxyBE.get(`admin/course/${JSON.stringify(payload)}`);
  },
  insertCourse(payload) {
    return proxyBE.post(`admin/course`, payload);
  },
  updateCourse(payload) {
    return proxyBE.put(`admin/course`, payload);
  },
  willDeleted(payload) {
    return proxyBE.put(`admin/course`, payload);
  },
  deleteCourse(id) {
    return proxyBE.delete(`admin/course/${id}`);
  },
  importCourse(payload) {
    return proxyBE.post(`admin/import`, payload);
  },
  courseSection(idCourse) {
    return proxyBE.get(`sections/idparent/${idCourse}`);
  },
  insertSection(payload) {
    return proxyBE.post(`sections/`, payload);
  },
  updateSection(payload) {
    return proxyBE.put(`sections/`, payload);
  },
  loadCourseFromMoodle(courseCode) {
    const payload = mnemoService.buildPayload(
      "courseByShortName",
      "shortname",
      courseCode
    );
    return proxyApiMnemo.get("moodleExporter/?payload=" + encodeURI(payload));
  },
  verifyPayment(registrationCode) {
    const payload = mnemoService.buildPayloadObject("verifyPaymentOther", {
      registrationCode: registrationCode
    });
    return proxyApiMnemo.post("payment/", payload);
  },

  importQuizFromMoodle(payload) {
    return proxyBE.post(`admin/importQuiz`, payload);
  },
  deleteSection(id) {
    return proxyBE.delete(`sections/${id}`);
  },

  insertLesson(payload) {
    return proxyBE.post(`admin/lessons/`, payload);
  },
  updateLesson(payload) {
    return proxyBE.put(`admin/lessons/`, payload);
  },
  deleteLesson(id) {
    return proxyBE.delete(`admin/lessons/${id}`);
  },
  insertLessonAndResource(payload) {
    return proxyBE.post(`admin/lessonsandresource/`, payload);
  },
  updatetLessonAndResource(payload) {
    return proxyBE.put(`admin/lessonsandresource/`, payload);
  },
  loadLessonAndResource(id) {
    return proxyBE.get(`admin/lessonsandresource/${id}`);
  },
  insertQuizQuestions(payload) {
    return proxyBE.post(`admin/quiz/question`, payload);
  },
  quizAllQuestion(idQuiz) {
    return proxyBE.get(`admin/quiz/allquestion/${idQuiz}`);
  },
  deleteQuestion(id) {
    return proxyBE.delete(`admin/quiz/question/${id}`);
  },
  updateQuizQuestions(payload) {
    return proxyBE.put(`admin/quiz/question`, payload);
  },
  loadAnwsers(idQuestion) {
    return proxyBE.get(`admin/quiz/answer/${idQuestion}`);
  },
  deleteAnswer(id) {
    return proxyBE.delete(`admin/quiz/answer/${id}`);
  },
  insertQuestionsAnswer(payload) {
    return proxyBE.post(`admin/quiz/answer`, payload);
  },
  updateQuestionsAnswer(payload) {
    return proxyBE.put(`admin/quiz/answer`, payload);
  },
  courseType(payload) {
    return proxyBE.get(`admin/courseType/${JSON.stringify(payload)}`);
  },
  loadAllCourseType(payload) {
    return proxyBE.get(`admin/courseType/${JSON.stringify(payload)}`);
  },
  updateCourseType(payload) {
    return proxyBE.put(`admin/courseType`, payload);
  },
  insertCourseType(payload) {
    return proxyBE.post(`admin/courseType`, payload);
  },
  deleteCourseType(id) {
    return proxyBE.delete(`admin/courseType/${id}`);
  },
  quizByIDParent(idResource) {
    return proxyBE.get(`admin/quizByIdParent/${idResource}`);
  },
  insertQuiz(payload) {
    return proxyBE.post(`admin/quiz/insert`, payload);
  },
  updateQuiz(payload) {
    return proxyBE.post(`admin/quiz/update`, payload);
  },
  getUser(filter) {
    return proxyBE.get(`admin/user/${JSON.stringify(filter)}`);
  },
  getUserBySearch(filter) {
    return proxyBE.get(`admin/getUserBySearch/${JSON.stringify(filter)}`);
  },
  getUDAByExamDate(filter) {
    return proxyBE.get(`admin/getUDAByExamDate/${JSON.stringify(filter)}`);
  },
  changeUserStatus(id) {
    return proxyBE.put(`admin/user/change-status/${id}`);
  },
  deleteRequestGDPRConfirmed(id) {
    return proxyBE.put(`admin/user/delete-for-gdpr/${id}`);
  },
  changeUserReadOnly(payload) {
    return proxyBE.put(`admin/user/change-readonly`, payload);
  },
  deleteUser(id) {
    return proxyBE.delete(`admin/user/${id}`);
  },
  userDetail(filter) {
    return proxyBE.get(`admin/user/detail/${JSON.stringify(filter)}`);
  },
  collectAndSendTo(uid) {
    return proxyBE.get(`admin/user/collect/${uid}`);
  },
  collectUserEnrollment(uid) {
    return proxyBE.get(`admin/user/enrollment/${uid}`);
  },
  loadAllRepoQuiz(payload) {
    return proxyBE.get(`admin/repoquiz/${JSON.stringify(payload)}`);
  },
  cloneToRepository(payload) {
    return proxyBE.post(`admin/clonetorepository`, payload);
  },
  deleteRepoQuiz(id) {
    return proxyBE.delete(`admin/repoquiz/${id}`);
  },
  insertRepoQuiz(payload) {
    return proxyBE.post(`admin/repoquiz`, payload);
  },
  updateRepoQuiz(payload) {
    return proxyBE.put(`admin/repoquiz`, payload);
  },
  quizRepoAllQuestion(idQuiz) {
    return proxyBE.get(`admin/repoquiz/allquestion/${idQuiz}`);
  },
  deleteRepoQuestion(id) {
    return proxyBE.delete(`admin/repoquiz/question/${id}`);
  },
  loadRepoAnwsers(idQuestion) {
    return proxyBE.get(`admin/repoquiz/answer/${idQuestion}`);
  },
  loadResource(idResource) {
    return proxyBE.get(`admin/resources/${idResource}`);
  },

  deleteRepoAnswer(id) {
    return proxyBE.delete(`admin/repoquiz/answer/${id}`);
  },

  updateRepoQuizQuestions(payload) {
    return proxyBE.put(`admin/repoquiz/question`, payload);
  },

  insertRepoQuizQuestions(payload) {
    return proxyBE.post(`admin/repoquiz/question`, payload);
  },

  insertRepoQuestionsAnswer(payload) {
    return proxyBE.post(`admin/repoquiz/answer`, payload);
  },

  updateRepoQuestionsAnswer(payload) {
    return proxyBE.put(`admin/repoquiz/answer`, payload);
  },

  syncUserDataTo(reportUserData) {
    const payload = mnemoService.buildPayloadObject("", reportUserData);
    return proxyApiMnemo.post("admin/syncUserDataTo", payload);
  },
  syncEnrollmentDataTo(resEnrollment) {
    const payload = mnemoService.buildPayloadObject("", resEnrollment);
    return proxyApiMnemo.post("admin/syncEnrollmentDataTo", payload);
  },
  getPromotional(payload) {
    return proxyBE.get(`admin/promotional/${JSON.stringify(payload)}`);
  },
  deletePromotional(id) {
    return proxyBE.delete(`admin/promotional/${id}`);
  },
  changePromotionalStatus(id) {
    return proxyBE.put(`admin/promotional/change-status/${id}`);
  },
  insertPromotional(payload) {
    return proxyBE.post(`admin/promotional`, payload);
  },
  updatePromotional(payload) {
    return proxyBE.put(`admin/promotional`, payload);
  },
  verifyPromotionalCode(filter) {
    return proxyBE.get(`admin/promotional/verify/${JSON.stringify(filter)}`);
  },
  registerOnUserCourse(payload) {
    return proxyBE.post(`admin/user/registerOnUserCource/`, payload);
  },
  cloneFullModule(payload) {
    return proxyBE.post(`admin/course/cloneFullModule`, payload);
  },
  studentUpdateSyncIn(payload) {
    return proxyBE.put(`admin/studentpdate/sync-in`, payload);
  },
  userUpdateSyncIn(payload) {
    return proxyBE.post(`admin/studentpdate/update-user`, payload);
  },
  cloneFullCourse(payload) {
    return proxyBE.post(`admin/course/cloneFullCourse`, payload);
  },
  remotetestloadwait() {
    const payload = mnemoService.buildPayload("latatency", "shortname", 0);
    return proxyApiMnemo.get("test/?payload=" + encodeURI(payload));
  },
  getSiteconfig() {
    return proxyBE.get(`admin/siteconfig/`);
  },
  getSiteconfigDetail(id) {
    return proxyBE.get(`admin/siteconfig/${id}`);
  },
  insertSiteconfig(payload) {
    return proxyBE.post(`admin/siteconfig`, payload);
  },
  updateSiteconfig(payload) {
    return proxyBE.put(`admin/siteconfig`, payload);
  },
  loadAllBlogCategory(payload) {
    return proxyBE.get(`admin/blog-category/${JSON.stringify(payload)}`);
  },
  deleteBlogCategory(id) {
    return proxyBE.delete(`admin/blog-category/${id}`);
  },
  updateBlogcategory(payload) {
    return proxyBE.put(`admin/blog-category`, payload);
  },
  insertBlogcategory(payload) {
    return proxyBE.post(`admin/blog-category`, payload);
  },
  loadBlogArticlesByIdCategory(payload) {
    return proxyBE.get(`admin/blog-articles/${JSON.stringify(payload)}`);
  },
  deleteBlogArticle(id) {
    return proxyBE.delete(`admin/blog-article/${id}`);
  },
  loadBlogArticle(id) {
    return proxyBE.get(`admin/blog-article-detail/${id}`);
  },
  updateBlogArticle(payload) {
    return proxyBE.put(`admin/blog-article`, payload);
  },
  insertBlogArticle(payload) {
    return proxyBE.post(`admin/blog-article`, payload);
  },
  loadSurvey() {
    return proxyBE.get(`admin/surveys`);
  },
  insertSurvey(payload) {
    return proxyBE.post(`admin/survey`, payload);
  },
  loadPages() {
    return proxyBE.get(`admin/pages`);
  },
  deletePage(id) {
    return proxyBE.delete(`admin/page/${id}`);
  },
  loadPage(id) {
    return proxyBE.get(`admin/page/${id}`);
  },
  updatePage(payload) {
    return proxyBE.put(`admin/page`, payload);
  },
  insertPage(payload) {
    return proxyBE.post(`admin/page`, payload);
  },
  loadAllLibraryCategory(payload) {
    return proxyBE.get(`admin/library-category/${JSON.stringify(payload)}`);
  },
  deleteLibraryCategory(id) {
    return proxyBE.delete(`admin/library-category/${id}`);
  },
  insertLibrarycategory(payload) {
    return proxyBE.post(`admin/library-category`, payload);
  },
  updateLibrarycategory(payload) {
    return proxyBE.put(`admin/library-category`, payload);
  },
  insertLibraryauthor(payload) {
    return proxyBE.post(`admin/library-author`, payload);
  },
  loadAllLibraryAuthor(payload) {
    return proxyBE.get(`admin/library-author/${JSON.stringify(payload)}`);
  },
  deleteLibraryAuthor(id) {
    return proxyBE.delete(`admin/library-author/${id}`);
  },
  updateLibraryauthor(payload) {
    return proxyBE.put(`admin/library-author`, payload);
  },
  loadAllLibraryBook(payload) {
    return proxyBE.get(`admin/library-book/${JSON.stringify(payload)}`);
  },
  deleteLibraryBook(id) {
    return proxyBE.delete(`admin/library-book/${id}`);
  },
  insertLibrarybook(payload) {
    return proxyBE.post(`admin/library-book`, payload);
  },
  updateLibrarybook(payload) {
    return proxyBE.put(`admin/library-book`, payload);
  },
  insertUserEnrollmentAbilitation(payload) {
    return proxyBE.post(`admin/user/enrollment-abilitation`, payload);
  },
  loadAllAudioAssetCategory() {
    return proxyBE.get(`admin/audio-asset-category`);
  },
  loadAudioAssetCategory(id) {
    return proxyBE.get(`admin/audio-asset-category/${id}`);
  },
  insertAudioCategory(payload) {
    return proxyBE.post(`admin/audio-asset-category`, payload);
  },
  updateAudioCategory(payload) {
    return proxyBE.put(`admin/audio-asset-category`, payload);
  },
  deleteAudioCategory(id) {
    return proxyBE.delete(`admin/audio-asset-category/${id}`);
  },
  loadAudioAssetByCategory(idAudioCategory) {
    return proxyBE.get(`admin/audio-asset-file-by-category/${idAudioCategory}`);
  },
  insertAudioFile(payload) {
    return proxyBE.post(`admin/audio-asset-file`, payload);
  },
  updateAudioFile(payload) {
    return proxyBE.put(`admin/audio-asset-file`, payload);
  },
  deleteAudioFile(idAudioCategory) {
    return proxyBE.delete(`admin/audio-asset-file/${idAudioCategory}`);
  },
  loadAudioAsset(idAudioCategory, idAudio) {
    return proxyBE.get(`admin/audio-asset-file/${idAudioCategory}/${idAudio}`);
  },
  updateVerifyCF(payload) {
    return proxyBE.put(`admin/user/updateVerifyCF`, payload);
  },
  listAllContactRequest(type) {
    return proxyBE.get(`admin/contactRequest/listAll/${type}`);
  },
  loadLessonLiveSchedule(payload) {
    return proxyBE.get(`admin/lessonLiveSchedule/${JSON.stringify(payload)}`);
  },
  deleteLessonLiveSchedule(id) {
    return proxyBE.delete(`admin/lessonLiveSchedule/${id}`);
  },
  updateLessonLiveSchedule(payload) {
    return proxyBE.put(`admin/lessonLiveSchedule`, payload);
  },
  insertLessonLiveSchedule(payload) {
    return proxyBE.post(`admin/lessonLiveSchedule`, payload);
  },
  loadLessonLiveClass(payload) {
    return proxyBE.get(`admin/lessonLiveClass/${JSON.stringify(payload)}`);
  },
  updateLessonLiveClass(payload) {
    return proxyBE.put(`admin/lessonLiveClass`, payload);
  },
  insertLessonLiveClass(payload) {
    return proxyBE.post(`admin/lessonLiveClass`, payload);
  },
  loadDataSetToDownload(idClass) {
    return proxyBE.get(`admin/lessonLiveClassBooking/${idClass}`);
  },
  loadDataSetToDownloadCSV(idClass, competitionClass, idLesson) {
    return proxyBE.get(
      `admin/lessonLiveClassBooking/${idClass}/${competitionClass}/${idLesson}`
    );
  },

  insertLessonLiveAnalyticsSlido(idLessonLiveSchedule) {
    return proxyBE.post(
      `admin/lessonLiveAnalytics/slido/`,
      idLessonLiveSchedule
    );
  },

  insertLessonLiveAnalyticsWebex(idLessonLiveSchedule) {
    return proxyBE.post(`admin/lessonLiveAnalytics/webex/`, {
      idLessonLiveSchedule,
    });
  },
  enableAttendance(idLessonLiveSchedule) {
    return proxyBE.put(`admin/lessonLiveAnalytics/webex/attendace-enable/`, {
      idLessonLiveSchedule,
      enable: true,
    });
  },
  disableAttendance(idLessonLiveSchedule) {
    return proxyBE.put(`admin/lessonLiveAnalytics/webex/attendace-enable/`, {
      idLessonLiveSchedule,
      enable: false,
    });
  },
  listStudents(examSite, idExamSession, academicYear) {
    return proxyBE.get(
      `admin/lessonLiveAnalytics/user-list-by-site/${examSite}/${idExamSession}/${academicYear}`
    );
  },
  lessonLiveAnalytics(examSite, idExamSession, academicYear) {
    return proxyBE.post(
      `admin/lessonLiveAnalytics/webex-resume-examSite/${examSite}/${idExamSession}/${academicYear}`         
    );
  },
  lessonLiveAnalyticStudent(registrationCode, academicYear) {
    return proxyBE.post('admin/lessonLiveAnalytics/webex-resume-user/', { registrationCode, academicYear });     
  },
  loadLessonLiveEdition(payload) {
    return proxyBE.get(`admin/lessonLiveEdition/${JSON.stringify(payload)}`);
  },
  updateLessonLiveEdition(payload) {
    return proxyBE.put(`admin/lessonLiveEdition`, payload);
  },
  insertLessonLiveEdition(payload) {
    return proxyBE.post(`admin/lessonLiveEdition`, payload);
  },
  loadLessonLiveEditionExamSession() {
    return proxyBE.get(`admin/lessonLiveEditionExamSession`);
  },
  loadLessonLiveCalendarWebex() {
    return proxyBE.get(`admin/loadLessonLiveCalendarWebex`);
  },
  loadFullSetToDownload(idClass, date, hourStart) {
    return proxyBE.get(
      `admin/lessonLiveClassBooking/full/dataset/` +
        idClass +
        `/` +
        date +
        `/` +
        hourStart
    );
  },
  getDataByQrRegistrationCode(registrationCode) {
    return proxyBE.get(`admin/getDataByQrRegistrationCode/${registrationCode}`);
  },
  uploadLessonLiveClassCalendar(payload) {
    return proxyBE.post(`admin/lessonLiveClassCalendar/webex/upload`, payload);
  },
};
