import { MnemoLoading } from "components/misc";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "design-react-kit";
import { adminService, dateHelper, networkErrorHelper } from "libs";
import React, { Component } from "react";
import QrReader from "react-qr-reader";

class QrCodeReader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showScanner: true,
      result: "",
      continuousScan: false,
      isLoading: false,
      collapseOpen1: false,
      collapseOpen2: false,
    };
  }

  autoRescan = (continuousScan, delay = 1000) => {
    if (continuousScan) {
      setTimeout(() => {
        this.newScan();
      }, delay);
    }
  }; //autoRescan

  handleScan = (registrationCode = false) => {
    if (registrationCode) {
      //this.setState({ results: data });
      const startLoading = setTimeout(() => {
        this.setState({ isLoading: true });
      }, 1500);

      //--- get remote info ---
      adminService
        .getDataByQrRegistrationCode(registrationCode)
        .then((data) => {
          this.setState({
            showScanner: false,
            isLoading: false,
            result:
              data.data.payload.result.length > 0
                ? data.data.payload.result[0]
                : "",
          });

          this.beep(4, 1500, 125);

          clearTimeout(startLoading);
        })
        .catch((errors) => {
          if (networkErrorHelper.is404(errors)) {
            networkErrorHelper.notify(errors);
          }

          this.beep(4, 250, 125);

          this.setState({ isLoading: false });
          clearTimeout(startLoading);
          /** auto start new scan after */
          //this.autoRescan(this.state.continuousScan, 5000);
        });
    }
  };

  handleError = (err) => {
    this.beep(4, 250, 125);
    console.error(err);
    /** auto start new scan after */
    //this.autoRescan(this.state.continuousScan, 3000);
  };

  newScan = () => {
    this.setState({ showScanner: true, result: "" });
  };

  beep = (vol, freq, duration) => {
    let a = new AudioContext();
    let v = a.createOscillator();
    let u = a.createGain();
    v.connect(u);
    v.frequency.value = freq;
    v.type = "square";
    u.connect(a.destination);
    u.gain.value = vol * 0.01;
    v.start(a.currentTime);
    v.stop(a.currentTime + duration * 0.001);
  }; //beep

  /*manageAutoScan = () => {
    this.setState({ continuousScan: !this.state.continuousScan });
  };*/

  toggle = (id) => {
    this.setState({
      ...this.state,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`],
    });
  };

  //--- ---

  render() {
    const { showScanner, isLoading, result, collapseOpen1, collapseOpen2 } =
      this.state;

    if (isLoading) {
      return <MnemoLoading />;
    }

    return (
      <>
        {showScanner && (
          <div>
            <div className="titleCameraReader">Controllo Presenze</div>
            <QrReader
              delay={400}
              onError={this.handleError}
              onScan={this.handleScan}
              resolution={700}
              className="cameraReader"
            />

            <p className="scanNotes">
              puoi procedere con la scansione di un QrCode valido
            </p>
          </div>
        )}

        {!showScanner && (
          <div className="userData">
            <div className="titleCameraReader">VERIFICA DATI</div>

            <ul className="QrUserData">
              <li>
                Codice Registrazione: <b>{result.registrationCode}</b>
              </li>
              <li>
                Anno Accademico: <b>{result.aa}</b>
              </li>
              <li>
                Codice Corso: <b>{result.courseCode}</b>
              </li>
              <li>
                Abilitato: <b>{result.enabled ? "SI" : "NO"}</b>
              </li>
            </ul>

            <Accordion>
              <AccordionHeader
                active={collapseOpen1}
                onToggle={() => this.toggle(1)}>
                <h4>Dati Anagrafici</h4>
              </AccordionHeader>
              <AccordionBody active={collapseOpen1}>
                <ul className="QrUserData">
                  <li>
                    Nome: <b>{result.name}</b>
                  </li>
                  <li>
                    Cognome: <b>{result.surname}</b>
                  </li>
                  <li>
                    Email: <b>{result.email}</b>
                  </li>
                  <li>
                    Telefono: <b>{result.mobile}</b>
                  </li>
                  <li>
                    Genere: <b>{result.gender === "m" ? "Uomo" : "Donna"}</b>
                  </li>
                  <li className="my-2">
                    <b>Luogo di Nascita</b>
                  </li>
                  {!result.isForeign && (
                    <>
                      <li>
                        Comune: <b>{result.city}</b>
                      </li>
                      <li>
                        CAP: <b>{result.cap}</b>
                      </li>
                      <li>
                        Provincia: <b>{result.province}</b>
                      </li>
                      <li>
                        Nazione: <b>{result.nationality}</b>
                      </li>
                      <li>
                        Data di Nascita:{" "}
                        <b>{dateHelper.toITDate(result.dob)}</b>
                      </li>
                    </>
                  )}
                  {result.isForeign && (
                    <>
                      <li>
                        Città estera: <b>{result.cityForeign}</b>
                      </li>
                      <li>
                        ZipCode: <b>{result.cap || result.zip}</b>
                      </li>
                      <li>
                        Nazione: <b>{result.nationality || result.nation}</b>
                      </li>
                      <li>
                        Data di Nascita:{" "}
                        <b>{dateHelper.toITDate(result.dob)}</b>
                      </li>
                    </>
                  )}
                </ul>
              </AccordionBody>
            </Accordion>

            <p className="serviceBtn">
              <Button
                className="checkInBtn"
                onClick={() => {
                  alert("deve segnare un ingresso");
                }}>
                INGRESSO
              </Button>
              <Button
                className="checkOutBtn"
                onClick={() => {
                  alert("deve segnare una uscita");
                }}>
                USCITA
              </Button>
              <Button
                className="reScanBtn"
                onClick={() => {
                  this.newScan();
                }}>
                SCANNER
              </Button>
            </p>
          </div>
        )}

        {/*!this.state.continuousScan && (
          <button
            className="newScan newScanON"
            onClick={(e) => {
              this.newScan(e);
            }}>
            new scan
          </button>
        )*/}

        {/*
          (configApp.continuousScan && !this.state.continuousScan && validate === 'off' && !showInfo) && (
              <button className="btnAutoScan" onClick={()=>{this.manageAutoScan()}}>attiva scansione automatica continua</button>
          )
        */}
      </>
    );
  }
}

export default QrCodeReader;
