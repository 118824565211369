import React, { Component } from 'react';

import { Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { ROUTES } from 'const';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export class PageWhoAre extends Component {

    render() {
        const title = 'La nostra Storia';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <section id="whoare-section">
                    <Row>
                        <Col lg={6} md={6}>
                        </Col>
                        <Col lg={6} md={6}>
                            <div id="whoare-box" className="white-bg px-4 pb-5" >
                                <h6>Da oltre 15 anni al fianco della formazione</h6>
                                <p>Quando all'inizio del XXI secolo abbiamo progettato e successivamente costituito l'Associazione Mnemosine, il nostro primo obiettivo è stato quello di offrire validi e utili strumenti a chi si proiettava (e si proietta) come educatore nel mondo scolastico.
                                    <br />Con il tempo ci siamo resi conto che molti Enti nel tentativo di fare solo ed esclusivamente business, hanno tentato e tentano ancora oggi di imitare l'Associazione Mnemosine alleandosi di anno in anno con differenti Istituti Universitari alla ricerca di “qualcuno” che offra Corsi sempre più economici e conseguentemente poco qualificati.
                                    <br />Mettere in guardia da tali realtà sarebbe un "gioco facile".
                                    <br />Cosa può invogliare un Docente (che non conosce queste dinamiche) a fidarsi di un'organizzazione piuttosto che un'altra? Basta solo qualche Euro in meno per "guidare" la scelta?
                                    <br />L'unico elemento da prendere in considerazione è la <b>“Storia”</b> di un Ente.</p>
                            </div>
                        </Col>
                    </Row>
                </section>
                <section className="m-5">
                    <img
                        src="/img/mnemo/logo_simple.webp"
                        alt="Mnemosine"
                    />
                    <ul>
                        <li>É un <b>Ente Accreditato MIUR</b> per la Formazione del personale scolastico;</li>
                        <li>Ha <b>prestigiose sedi</b> di sua proprietà in diverse Regioni Italiane;</li>
                        <li>Dal 2005 ha proposto annualmente <b>circa 200 Corsi Universitari</b> con esami in Italia (75 città) ed all'estero (Parigi, Londra, Madrid, Atene e Lisbona);</li>
                        <li>Non ha mai ottenuto o chiesto alcun <b>finanziamento pubblico</b>;</li>
                        <li>Finanzia alcune Riviste <b>Scientifiche Internazionali:</b>
                            <ul>
                                <li>“Revista de Estudios Interculturales” con l'Università Statale di Granada;</li>
                                <li>“Asociación Internacional de Psicología Evolutiva y de la Infancia, Adolescencia, Mayores y Discapacidad” con l'Università Statale di Badajoz;</li>
                                <li>“PAN” . Rivista di Filologia Latina;</li>
                            </ul>
                        </li>
                        <li>Ha finanziato diversi <b>Assegni di ricerca</b> e <b>Borse di Studio</b> all'Università del Salento e all'Università di Palermo;</li>
                        <li>Ha organizzato diversi <b>Convegni Internazionali</b> nelle seguenti città:
                            <ul>
                                <li>Siviglia;</li>
                                <li>Badajoz;</li>
                                <li>Palermo;</li>
                                <li>Santiago di Compostela;</li>
                                <li>Atene;</li>
                            </ul>
                        </li>
                        <li>Ha patrocinio la realizzazione dei <b>Convegni Nazionalidi Alto profilo scientifico</b>:
                            <ul>
                                <li>“La Gestione dell'Ictus” tenutosi presso il Teatro “S. Alessandro” della propria sede Nazionale;</li>
                                <li>“Responsabilità e merito nel mondo antico” tenutosi nella città di Palermo;</li >
                            </ul>
                        </li>
                        <li>Ha accordi Internazionali per la <b>Ricerca e lo Sviluppo</b> delle attività formative con le seguenti Università Estere:
                            <ul>
                                <li>Università Statale di Granada (Spagna);</li>
                                <li>Università Statale di Cordoba (Spagna);</li>
                                <li>Università Statale di Valencia (Spagna);</li>
                                <li>Università Cattolica di Valencia (Spagna);</li>
                                <li>Università Cattolica di Murcia (Spagna);</li>
                                <li>Università Statale di Almeria (Spagna);</li>
                                <li>Università Statale di Huelva (Spagna);</li>
                                <li>Università Statale di Murcia (Spagna);</li>                               
                                <li>Università Normale di Shenyang (Cina);</li>
                                <li>Henan Agricultural University (Cina);</li>
                                <li>Università di Zheng Zhou (Cina);</li>
                                <li>Università tecnologia elettronica di Guilin (Cina)</li>
                            </ul>
                        </li>
                        <li>Ha svolto svariate <b>iniziative per la legalità</b> con l'Associazione Libera e con Associazione “Via Maqueda città”;</li>
                        <li>Ha elargito <b>donazioni</b> a: Unicef, Istituto Maria Immacolata, Ass. Meter contro la Pedo-pornografia, Leoni Sicani (disabili che praticano Hockey in carrozzina) , ecc….</li>
                        <li>Ha finanziato diversi concorsi per le scuole Italiane (ultimo il contributo per il progetto sul “Sogno Americano”);</li>
                        <li>Ha contribuito alla realizzazione delle seguenti <b>pubblicazioni</b> inerenti il settore scuola e formazione:
                            <ul>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), Collana: Costruire Competenze. Materiali didattici per la formazione. Centro Siciliano Sturzo, Palermo, voll.4.</li>
                                <li>Ce.S.S. Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), Il Conflitto in Costruire Competenze. Materiali didattici per la formazione, vol. 1,</li>
                                <li>Ce.S.S. Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), La gestione del conflitto in Costruire Competenze. Materiali didattici per la formazione, vol. 2,</li>
                                <li>Ce.S.S. Centro Siciliano Sturzo, Palermo. Ce.S.S. Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), I solidi platonici in Costruire Competenze. Materiali didattici per la formazione, vol. 3,</li>
                                <li>Ce.S.S. Centro Siciliano Sturzo - Ass. Mnemosine, Liana Arcuri (a cura di), Diversabilità e sostegno in Costruire Competenze. Materiali didattici per la formazione, vol. 4,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), Collana: Percorsi per il sociale. Materiali didattici per la formazione nel sociale.</li>
                                <li>Ce.S.S. Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), La globalizzazione in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 2,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine - ES Empowerement Sociale. Ass. Culturale per gli interventi di comunità (a cura di), Il sé sociale in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 3,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine (a cura di), Ansia, paura e rischio in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 4,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine - ES Empowerement Sociale. Ass. Culturale per gli interventi di comunità, Carolina Messina (a cura di), Instant project in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 5,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine - ES Empowerement Sociale. Ass. Culturale per gli interventi di comunità, Sergio Di Minica (a cura di), Autolesionismo e suicidio in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 6,</li>
                                <li>Centro Siciliano Sturzo - Ass. Mnemosine - ES Empowerement Sociale. Ass. Culturale per gli interventi di comunità Alessandro Genco (a cura di), Il lavoro di strada in Percorsi per il sociale. Materiali didattici per la formazione nel sociale, vol. 7,</li>
                                <li>Barbera - W. Moro (a cura di), Superare il Concorso. Fare il Dirigente Scolastico, Mursia, Milano 2010;</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Collana: Costruire Formazione, Centro Siciliano Sturzo, Palermo, voll. 10;</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), La dimensione simbolica dell'insegnamento -apprendimento in Costruire</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Modelli e strategie di intervento psicosociale nei contesti formativi e strategie cooperative di apprendimento in Costruire formazione, vol. II,</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Gruppi di apprendimento in Costruire formazione, vol. III;</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Metodologie attive per l'apprendimento in Costruire formazione, vol. IV,</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Tecniche narrative per la didattica in Costruire formazione, vol. V,</li>
                                <li>Euroformazione - Ass. Mnemosine - Università del Salento (a cura di), Metodologia dell'insegnamento artistico-matematico in Costruire formazione, vol. VI,</li>
                                <li>Mnemosine - Università del Salento (a cura di), Sport e disabilità in Costruire formazione, vol. VII;</li>
                                <li>Mnemosine - Università del Salento (a cura di), Metodologia e didattica in Costruire formazione, vol. VIII;</li>
                                <li>Mnemosine - Università del Salento (a cura di), Modelli di intervento nelle relazioni familiari e couseling familiare in Costruire formazione, Vol. IX;</li>
                                <li>Mnemosine (a cura di), La dinamica di gruppo in Costruire formazione, vol. X,</li>
                                <li>Mnemosine (a cura di), Politiche sociali per l'handicap e la disabilità. Introduzione alle politiche sociali, vol. I.</li>
                            </ul>
                        </li>
                    </ul >
                    <p>Ecco perché scegliere Mnemosine!. <a className="link-dark" href="https://www.formazionedocenti.it/files/chi_siamo/Curriculum_Mnemosine.pdf">Curriculum Ente</a></p>


                </section >
                <section>
                    <div className="section section-muted text-center mb-5">
                        <h2>Siamo presenti in tutta Italia</h2>
                        <Link to={ROUTES.PAGE_CONTACT} className="m-5 btn btn-primary bg-dark">Scopri le nostre sedi</Link>
                    </div>
                </section>
            </>);
    }
}