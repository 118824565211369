import React, { Component } from "react";
import { MnemoLoading, Title } from "components/misc";
import { networkErrorHelper, utilsService } from "libs";
import { MSelect, MTextArea } from "components/forms";
import ReactHtmlParser from "react-html-parser";
import { Button } from "design-react-kit";
import { MTable } from "components/table";
import dayjs from "dayjs";

class SqlEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentQuery: "",
      PAYLOAD_QUERIES: [],
      resQueries: [],
      resRun: false,
      rndKey: 0,
      tableConfig: {},
    };

    this.queryTextAreaRef = React.createRef();
  }

  //--- ---

  componentDidMount() {
    this.loadRemoteQueries();
  }

  loadRemoteQueries = async () => {
    this.setState({ isLoading: true });

    utilsService
      .loadRemoteQueries()
      .then((res) => {
        //console.log(res.data.payload.result);

        const optionsArr = res.data.payload.result.map((item) => {
          return { value: item.id, label: item.title };
        });

        optionsArr.unshift({ label: "Seleziona una Query", value: "" });

        this.setState({
          PAYLOAD_QUERIES: {
            options: optionsArr,
          },
          isLoading: false,
          resQueries: res.data.payload.result,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeQuery = (id, value) => {
    const res = (this.state.resQueries || []).find(
      (item) => parseInt(item.id) === parseInt(value)
    );

    if (!res) {
      this.setState({
        currentQuery: "",
        resRun: false,
      });
    } else {
      this.setState({
        currentQuery: res,
        resRun: false,
      });
    }
  };

  onChangeQueryEditor = (field, value) => {
    this.setState({
      currentQuery: { ...this.state.currentQuery, query: value },
    });
  };

  run = () => {
    this.setState({ isLoading: true });
    const queryValue = this.queryTextAreaRef.current.props.value;

    utilsService
      .runQuery(queryValue)
      .then((res) => {
        //--- create dynamic tableConfig ---
        let columns = [];
        let actions = [];

        res.data.payload.result.fields.forEach((item) => {
          columns.push({
            id: item.name,
            Header: item.name,
            accessor: item.name,
            isVisible: true,
          });
        });

        let currentTableConfig = { columns, actions };

        //--- prepare result to table ---
        console.log(res.data.payload.result.result);

        this.setState({
          isLoading: false,
          resRun: res.data.payload.result,
          rndKey: Math.floor(Math.random() * 1000 + 1),
          tableConfig: currentTableConfig,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
        networkErrorHelper.notify(error);
      });
  };

  export = () => {
    //--- prepare header ---
    let str = "";
    for (let i = 0; i < this.state.resRun.fields.length; i++) {
      str += this.state.resRun.fields[i].name + ";";
    }
    str += "\r\n";

    const rows = this.state.resRun.result;

    for (let i = 0; i < rows.length; i++) {
      let line = "";
      for (let index in rows[i]) {
        if (line !== "") line += ";";
        line += rows[i][index];
      }

      str += line + "\r\n";
    }

    let downloadLink = document.createElement("a");
    downloadLink.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(str);
    downloadLink.download =
      this.state.currentQuery.title.replace(/\s+/g, "") +
      "_" +
      dayjs().format("DD_MM_YYYY_H_m_s") +
      ".csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //--- ---

  render() {
    let {
      isLoading,
      currentQuery,
      PAYLOAD_QUERIES,
      resRun,
      tableConfig,
      rndKey,
    } = this.state;
    if (isLoading) return <MnemoLoading />;

    return (
      <>
        <div className="container mt-5 mb-4">
          <section className="my-4">
            <Title>Azioni su DB: SQL Editor</Title>

            <label>Archivio Query:</label>
            <MSelect
              onChange={this.onChangeQuery}
              id="completitionClass"
              payload={PAYLOAD_QUERIES}
              className="mb-2"></MSelect>

            {currentQuery !== "" && (
              <div>
                <h3>{ReactHtmlParser(currentQuery.title)}</h3>
                <h5>{ReactHtmlParser(currentQuery.description)}</h5>
                <hr />
              </div>
            )}

            <label>Query Editor:</label>

            <MTextArea
              ref={this.queryTextAreaRef}
              value={currentQuery.query}
              rows={6}
              onChange={this.onChangeQueryEditor}
            />
            <hr />
            <Button
              className="ml-2"
              color="primary bg-dark"
              disabled={currentQuery === ""}
              onClick={() => this.run()}>
              ESEGUI
            </Button>
          </section>

          {resRun && (
            <section className="m-0">
              <hr />
              <Button
                className="ml-2"
                color="primary bg-dark"
                onClick={() => this.export()}>
                ESPORTA {resRun.count} Risultati
              </Button>
              <hr />
              <MTable
                key={rndKey}
                tableData={resRun.result}
                tableConfig={tableConfig}
              />
            </section>
          )}
        </div>
      </>
    );
  }
}

export default SqlEditor;
