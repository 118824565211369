import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import {
  Header,
  HeaderContent,
  HeaderToggler,
  Collapse,
  Nav,
  NavItem,
  Icon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  LinkList,
} from "design-react-kit";

import { ROUTES } from "../../const";
import { getCurrentUser } from "store/actions";

export class NHSteward extends Component {
  state = {
    collapsed: true,
  };

  manageEvtByLink = (link) => {
    this.setState({ collapsed: true });
  };

  //--- ---

  render() {
    const isOpen = !this.state.collapsed;
    const user = getCurrentUser();

    return (
      <>
        <Header small theme="" type="navbar">
          <HeaderContent expand="lg" megamenu>
            <HeaderToggler
              aria-controls="nav1"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ collapsed: isOpen })}>
              <Icon icon="it-burger" />
            </HeaderToggler>
            <Collapse
              isOpen={isOpen}
              navbar
              header
              onOverlayClick={() => this.setState({ collapsed: true })}>
              <div className="menu-wrapper mnemo-dark">
                <Nav navbar tag="ul" vertical={false}>
                  <NavItem
                    key={`menu-item-close`}
                    tag="li"
                    onClick={() => this.setState({ collapsed: isOpen })}>
                    <div className="mnemo-close-li">
                      CHIUDI <Icon icon="it-close-circle" padding={false} />
                    </div>
                  </NavItem>

                  <NavItem tag="li" onClick={() => this.setState({ collapsed: isOpen })}>
                    <NavLink className="nav-link" to={ROUTES.STEWARD_VERIFY_IDENTITY} ag="a">
                      QrScanner
                    </NavLink>
                  </NavItem>

                  <NavItem tag="li" onClick={this.props.logout}>
                    <NavLink className="nav-link" to="/" ag="a">
                      Esci
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Collapse>
          </HeaderContent>
        </Header>
      </>
    );
  }
}
