import React, { Component } from "react";
import { MnemoLoading, Title, SuccessNotification } from "components/misc";
import { Container } from "design-react-kit";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { adminService, networkErrorHelper } from "libs";

class LessonLiveClassUploadCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      idClass: this.props.match.params.idClass || 0,
      selectedFile: null, // Stato per il file selezionato
    };
  }

  // Funzione per inviare il file all'API
  uploadFile = async () => {
    const { selectedFile, idClass } = this.state;

    if (!selectedFile) return;

    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("idClass", idClass);

    adminService
      .uploadLessonLiveClassCalendar(formData)
      .then(({ data }) => {
        SuccessNotification.render('Importazione andata a buon fine.', "Comunicazione");

        this.setState({
          loading: false,
          selectedFile: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          selectedFile: null,
        });

        networkErrorHelper.notify(error);
        console.log(error);
      });
  };

  render() {
    const { loading, selectedFile } = this.state;

    return (
      <div className="container mt-3 mb-4">
        <section className="my-4">
          {loading ? (
            <MnemoLoading />
          ) : (
            <Container>
              <Title>Calendario Aule Webex Specifiche</Title>
              <hr />

              <div className="mt-4">
                <div className="uploadForm">
                  {/* Input file nascosto */}
                  <input
                    className="uploadFormFile"
                    type="file"
                    accept=".csv, .xls, .xlsx"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      this.setState({ selectedFile: file });
                    }}
                    style={{ display: "none" }}
                    ref={(input) => (this.fileInput = input)}
                  />

                  {/* Bottone per selezionare il file */}
                  <div className="wrapperUploadBtn">
                    <button
                      className="btn btn-primary bg-dark"
                      onClick={() => this.fileInput.click()}>
                      Seleziona un file da importare
                    </button>

                    {selectedFile && (
                      <p className="uploadFileName">{selectedFile.name}</p>
                    )}
                  </div>

                  {/* Bottone per caricare il file */}
                  <button
                    className="btn btn-primary bg-dark"
                    onClick={this.uploadFile}
                    disabled={!selectedFile}>
                    IMPORTA FILE
                  </button>
                </div>
              </div>
            </Container>
          )}
        </section>
      </div>
    );
  }
}

export default withRouter(LessonLiveClassUploadCalendar);
