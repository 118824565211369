import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export class SuccessNotification {

    static render( message = '_', title = '_') {
       
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });        
    }    
}