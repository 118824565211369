import React, { Component } from "react";
import { NHPublic, NHStudent, NHAdmin, NHSegreteria, NHEditor, NHRevisore } from "components/navigation";
import { NHSteward } from "components/navigation/NHSteward";

export class NavHeader extends Component {

  
  loadNHComponent = (isLoggedIn, roles) =>{
   
    if(!isLoggedIn){
      return <NHPublic />
    }

    if(isLoggedIn && roles === 'student'){
      return <NHStudent 
        user={this.props.user}
        logout = {this.props.logout}
      />
    }

    if(isLoggedIn && roles === 'admin'){
      return <NHAdmin 
        logout = {this.props.logout}
      />
    }

    if(isLoggedIn && roles === 'segreteria'){
      return <NHSegreteria 
        logout = {this.props.logout}
      />
    }

    if(isLoggedIn && roles === 'editor'){
      return <NHEditor 
        logout = {this.props.logout}
      />
    }

    if(isLoggedIn && roles === 'revisor'){
      return <NHRevisore 
        logout = {this.props.logout}
      />
    }

    if(isLoggedIn && roles === 'steward'){
      return <NHSteward
        logout = {this.props.logout}
      />
    }

  }//loadNHComponent
  

  render() {

    const { isLoggedIn, roles } = this.props;
   
    return (
      <>
        {this.loadNHComponent(isLoggedIn, roles)}
      </>
    );
  }
}