import React, { Component } from "react";
import { Container } from "design-react-kit";
import { MTable } from "components/table";
import { MnemoLoading, ModalHandler, BoxDanger } from "components/misc";
import { NavLink } from "react-router-dom";
import { ROUTES } from "const";
import { networkErrorHelper, adminService } from "libs";
import { MSelect } from "components/forms";
import { withRouter } from "react-router-dom";

class RepoQuiz extends Component {
  constructor(props) {
    super(props);

    const currentYear = new Date().getFullYear();
    const tmpPopolateAA = { options: [{ label: "Seleziona", value: -1 }] };
    for (let year = currentYear; year >= 2020; year--) {
      tmpPopolateAA.options.push({ label: year.toString(), value: year });
    }

    this.state = {
      payload: [],
      loading: false,
      aa: process.env.REACT_APP_AA_YEAR,
      searchFilter: {
        searchFilter: {
          aa: process.env.REACT_APP_AA_YEAR,
        },
      },
      rndKey: 0,
      popolateAA: tmpPopolateAA
    };
  }

  //--- ---

  PAYLOADS = {
    aa: [],
  };

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "aa",
        Header: "A.A.",
        accessor: "aa", // accessor is the "key" in the data
      },
      {
        id: "code",
        Header: "Codice",
        accessor: "code", // accessor is the "key" in the data
      },
      {
        id: "title",
        Header: "Titolo",
        accessor: "title",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.REPOQUIZ_FORM + "/" + value;
        },
      },
      {
        id: "delete",
        accessor: "id", // field value received in the onclick event
        label: "Elimina",
        icon: "it-delete",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.deleteRecord(e, value);
        },
      },
    ],
  };

  //--- ---

  componentDidMount() {
    this.loadRemote(this.state.searchFilter);
  } //componentDidMount

  loadRemote = (filter) => {
    adminService
      .loadAllRepoQuiz(filter)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.repoQuiz).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        //--- construct option array ---
        const aa = data.payload.distinctAA.map(({ aa }) => ({
          label: aa,
          value: aa,
        }));
        this.PAYLOADS.aa = [];
        this.PAYLOADS.aa.push(...aa);
        this.PAYLOADS.aa.unshift({ label: `Seleziona`, value: -1 });
        //this.PAYLOADS.aa.unshift({ label: aa[0].label, value: aa[0].value });
        //--- construct option array ---

        this.setState({
          loading: false,
          payload: items,
          //popolateAA: { options: this.PAYLOADS.aa },
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }; //loadRemote

  filterByAA = (_, aa) => {
    this.setState(
      {
        aa: aa,
        searchFilter: {
          searchFilter: {
            aa: aa,
          },
        },
      },
      () => {
        this.loadRemote(this.state.searchFilter);
      }
    );
  }; //filterByAA

  deleteRecord = (e, value) => {
    ModalHandler.show(
      value,
      "deposito delle Domande",
      "Cancellazione definitiva, vuoi procedere?",
      null,
      this.deleteConfirmed
    );
  }; //deleteRecord

  deleteConfirmed = (value) => {
    adminService
      .deleteRepoQuiz(value)
      .then(({ data }) => {
        this.loadRemote(this.state.searchFilter);
      })
      .catch((error) => {
        console.log(error);
        if (networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  };

  //--- ---

  render() {
    const { loading } = this.state.loading;

    return (
      <>
        <section className="m-0 mt-5">
          <div className="form-row m-0 p-0">
            <div className="col-md-3">
              <MSelect
                id="aa"
                className="m-10"
                label="Anno Accademico"
                payload={this.state.popolateAA}
                onChange={this.filterByAA}
              />
            </div>

            <div className="col-md-9 text-right">
              <NavLink
                to={ROUTES.REPOQUIZ_FORM}
                className="btn btn-primary bg-dark pb-2 mx-1">
                Nuovo Deposito
              </NavLink>
            </div>
          </div>
        </section>

        <hr />

        <section id="repoQuiz" className="my-4">
          {loading ? (
            <MnemoLoading></MnemoLoading>
          ) : (
            <Container>
              <MTable
                key={this.state.rndKey}
                tableData={this.state.payload}
                tableConfig={this.tableConfig}
              />
            </Container>
          )}

          {this.state.payload.length === 0 && (
            <BoxDanger key="nrp-1" className="my-3">
              Ricerca non valida.
              <div className="small text-info">
                Nessun dato per questa selezione, cambia i parametri di ricerca.
              </div>
            </BoxDanger>
          )}
        </section>
      </>
    );
  }
}

export default withRouter(RepoQuiz);
