import React, { Component } from "react";

import "./CorrispondenzaTitoli.scss";

import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

export class CorrispondenzaTitoli extends Component {
  render() {
    return (
      <section className="corrispondenza-titoli">
        <div className="corrispondenza-titoli__wrapper">
          <h2>Corrispondenza Titoli di studio e Classi di Concorso</h2>
          <p>
            Vuoi sapere a quale insegnamento puoi accedere con il tuo titolo di
            studi?
          </p>
          <ButtonPrimary label="Scoprilo adesso" href={this.props.href} />
          <a className="float-right btn btn-primary bg-dark" href="https://www.formazionedocenti.it/page/ricerca-personale">Ricerca personale</a>
        </div>
      </section>
    );
  }
}

export default CorrispondenzaTitoli;
