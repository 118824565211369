import React from "react";

import {
  ValidatedForm,
  MFile,
} from "components/forms";

import {
  payloadBuilder,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import dayjs from "dayjs";

import { Card, CardBody, Button } from "design-react-kit";
import { ErrorNotification, MnemoLoading } from "components/misc";

export class AdminLessonLiveAnalyticsForm extends ValidatedForm {

  emptyFields = {
    webexAnalytics: this.props.idLessonLiveSchedule,
    slidoAnalytics: this.props.idLessonLiveSchedule,   
    document: this.props.idLessonLiveSchedule
  };

  FIELDS_GROUP = [
    [{
      id: "document",
      field: "document",
      label: "Report partecipazione Webex",
      infoText: "Caricare il report attività Webex",
      component: MFile,
      config: { endPoint: '/admin/lessonLiveAnalytics/webex-file', fieldName: 'document', maxFiles: 1, accept:".csv" },
      className: "col-md-12"
    }],
    [{
      id: "document",
      field: "document",
      label: "Report risposte slido, chat, etc",
      infoText: "Caricare il report risposte slido, le chat, etc",
      component: MFile,
      config: { endPoint: '/admin/lessonLiveAnalytics/slido-file',  fieldName: 'document', maxFiles: 5, accept:".xlsx,.txt,.csv"  },
      className: "col-md-12"
    }],
  ];

  //--- ---

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...this.emptyFields,      
      defaultValues: {  document: this.props.idLessonLiveSchedule, webexAnalytics: this.props.idLessonLiveSchedule, slidoAnalytics: this.props.idLessonLiveSchedule },
      formActive: true,
      idLessonLiveSchedule: this.props.idLessonLiveSchedule,
      webexImported: false,      
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadRemote(); 
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idLessonLiveSchedule) <= 0) {
      return false;
    }

    let filter = {
      searchFilter: {
        id: parseInt(this.props.idLessonLiveSchedule),
      },
    };

    adminService
      .loadLessonLiveSchedule(filter)
      .then(({ data }) => {
        //--- force date format to date ---
        data.payload.lessonLiveSchedule.date = dayjs(
          data.payload.lessonLiveSchedule.date
        ).format("YYYY-MM-DD");

        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.lessonLiveSchedule
        );

        const newState = {
          defaultValues,
          ...additionalState,
          loading: false,
          lessonLiveSchedule:  data.payload.lessonLiveSchedule,
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

   onCancel = () => {
    this.resetForm();
  }; //onCancel

  onBackPage = () => {
    window.history.back();
  };

  saveRemote = () => {
    payloadBuilder(this.state);    
  };

  importWebex = () => {

    this.setState({loading: true});   
    adminService
    .insertLessonLiveAnalyticsWebex(this.state.idLessonLiveSchedule)
      .then(({ data }) => {
        ErrorNotification.render("Analizzati " + data.payload.imported + " attività", "success");
        this.setState({loading: false, lessonLiveSchedule:  data.payload.lessonLiveSchedule});         
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  }

  disableAttendance = () => {
    this.setState({loading: true});   
    adminService
    .disableAttendance(this.state.idLessonLiveSchedule)
      .then(({ data }) => {
        
        this.setState({loading: false, lessonLiveSchedule:  data.payload.lessonLiveSchedule});   
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  }

  enableAttendance = () => {
    this.setState({loading: true});   
    adminService
    .enableAttendance(this.state.idLessonLiveSchedule)
      .then(({ data }) => {
        
        this.setState({loading: false, lessonLiveSchedule:  data.payload.lessonLiveSchedule});   
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  }
  
  importSlido = () => {
    this.setState({loading: true});   
    adminService
    .insertLessonLiveAnalyticsWebex(this.state.idLessonLiveSchedule)
      .then(({ data }) => {
        
        this.setState({loading: false});   
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  }

  render() {
    let { formActive, defaultValues, loading,  lessonLiveSchedule } = this.state;
    const { code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    const enabled = (lessonLiveSchedule) ? lessonLiveSchedule.enableAttendance : false;

    return (
      <>
         {loading && (<MnemoLoading />)}
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <form className="user" id="analyticsForm">
              {code}
            </form>

            <div className="mt-4">
              <Button color="primary bg-dark"  onClick={this.importWebex} >Analizza report attività webex </Button>
              {enabled ? 
              <Button color="primary bg-dark" className="mx-4"  onClick={this.disableAttendance} >Disabilita download attestati</Button>
              :
              <Button color="primary bg-dark" className="mx-4" onClick={this.enableAttendance} >Abilita download attestati</Button>
              } 
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
