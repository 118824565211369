import React, { Component } from "react";
import { ErrorNotification, MnemoLoading, Title } from "components/misc";
import { MInput, MSelect } from "components/forms";
import { Button, Row, Col, Icon } from "design-react-kit";
import { adminService, stringHelper } from "libs";
import { CM30_EDITION, RC30_EDITION, RCALL_EDITION, UM30_EDITION } from "const";

export class LessonLiveScheduleProcess extends Component {
  state = {
    loading: false,
    loadingError: "",
    city: "",
    idExamSession: 0,
    academicYear: 0,
    students: [],
    studentPayments: [],
  };

  PAYLOAD_YEAR = {
    options: [
      { label: "SELEZIONA UN ANNO ACCADEMICO", value: 0 },
      { label: "2023/2024", value: 2023 },
      { label: "2024/2025", value: 2024 }
    ]
  };

  PAYLOAD_EXAMSESSION = {
    2023: {
      options: [
        { label: "SELEZIONA UNA EDIZIONE", value: 0 },
        { label: "REGGIO - PRIMA", value: RC30_EDITION.first },
        { label: "REGGIO - SECONDA", value: RC30_EDITION.second },
        { label: "REGGIO - TERZA", value: RC30_EDITION.third },
        { label: "REGGIO - QUARTA", value: RC30_EDITION.fourth },
        { label: "UNICAMILLUS - SECONDA", value: UM30_EDITION.second },
        { label: "TCHAIKOVSKY - SECONDA", value: CM30_EDITION.second },
        { label: "60CFU ALL1", value: RCALL_EDITION.first_rc60 },
        { label: "30CFU ALL2", value: RCALL_EDITION.first_rc30 },
      ]
    },
    2024: {
      options: [
        { label: "SELEZIONA UNA EDIZIONE", value: 0 },
        { label: "REGGIO - PRIMA", value: RC30_EDITION.first_2024 },
        { label: "UNICAMILLUS - PRIMA", value: UM30_EDITION.first_2024 },
        { label: "TCHAIKOVSKY - PRIMA", value: CM30_EDITION.first_2024 },
      ]
    }
  };

  setAcademicYear = (_, value) => {
    const idExamSession = 0;
    this.setState({ academicYear: value, idExamSession });
  };

  setIdExamSession = (_, value) => {
    this.setState({ idExamSession: value });
  };

  setCity = (_, value) => {
    this.setState({ city: value });
  };

  loadStudents = () => {
    this.setState({ loading: true, loadingError: "", studentPayments: [] });
    const { city, idExamSession, academicYear } = this.state;

    if (idExamSession === 0) {
      ErrorNotification.render(
        "Devi specificare un edizione per poter procedere"
      );
      this.setState({ loading: false });
    } else if (city.length === 0) {
      ErrorNotification.render(
        "Devi inserire la città esami per poter procedere. Se vuoi tutte le citta scrivi 'TUTTE'"
      );
      this.setState({ loading: false });
    } else {
      adminService
        .listStudents(city, idExamSession, academicYear)
        .then(({ data }) => {
          this.setState({ students: data.payload.students, loading: false });
        })
        .catch((error) => {
          console.error("Error ", error);
          ErrorNotification.render("Errore  server");
          this.setState({ loading: false, loadingError: error });
        });
    }
  };

  analyzeStudents = () => {

    this.setState({ loading: true, loadingError: "" });
    const { students, city, idExamSession, academicYear } = this.state;

    if (students.length === 0) {
      ErrorNotification.render("Nessuno studente da analizzare");
      this.setState({ loading: false });
    } else {
      if (idExamSession === 0 || city.length === 0 || academicYear === 0) {
        ErrorNotification.render(
          "Dati inconsistenti. Ricarica l'elenco degli studenti tramite il pulsante 'Elenco studenti'"
        );
        this.setState({ loading: false });
      } else {
        adminService
          .lessonLiveAnalytics(city, idExamSession, academicYear)
          .then(({ data }) => {
            this.loadStudents();
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.error("Error ", error);
            ErrorNotification.render("Errore  server");
            this.setState({ loading: false, loadingError: error });
          });
      }
    }
  };

  analyzeStudent = (student) => {

    this.setState({ loading: true, loadingError: "" });
    const { academicYear } = this.state;

    adminService
      .lessonLiveAnalyticStudent(student.registrationCode, academicYear)
      .then(({ data }) => {
        this.loadStudents();
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("Error ", error);
        ErrorNotification.render("Errore  server");
        this.setState({ loading: false, loadingError: error });
      });

  };

  verifyPaymentStudents = () => {
    this.setState({ loading: true, loadingError: "", studentPayments: [] });
    const { students } = this.state;

    if (students.length === 0) {
      ErrorNotification.render("Nessuno studente da analizzare");
      this.setState({ loading: false });
    } else {
      for (let i = 0; i < students.length; i++) {
        const student = students[i];
        if (students[i].percent >= 0.7) {
          adminService
            .verifyPayment(student.registrationCode)
            .then(({ data }) => {
              if (data.payload.operation) {
                let studentPayments = this.state.studentPayments;
                studentPayments[student.registrationCode] =
                  data.payload.operation;
                this.setState({ studentPayments: studentPayments });
              }
            })
            .catch((error) => {
              console.error("Error ", error);
              ErrorNotification.render("Errore server");
              this.setState({ loading: false, loadingError: error });
            });
        } else {
          let studentPayments = this.state.studentPayments;
          studentPayments[student.registrationCode] =
            "Percentuale non raggiunta. Pagametno non generato.";
          this.setState({ studentPayments: studentPayments });
        }
      }

      this.setState({ loading: false });
    }
  };

  exportStudents = () => {
    const { students, city } = this.state;
    if (students.length === 0) {
      ErrorNotification.render("Nessuno studente da analizzare");
      this.setState({ loading: false });
    } else {
      let str =
        "CODICE REGISTRAZIONE;COGNOME;NOME;EMAIL;TELEFONO;CODICE CORSO;MINUTI COLLEGAMENTO;PERCENTUALE\r\n";

      for (let i = 0; i < students.length; i++) {
        if (students[i].percent >= 0.7) {
          let percent = students[i].percent
            ? students[i].percent.toString().replace(".", ",")
            : "0";
          let line = ` ${students[i].registrationCode};${students[i].surname};${students[i].name};${students[i].email};${students[i].mobile};${students[i].courseCode};${students[i].competitionclass};${students[i].minutes};${percent}`;
          str += line + "\r\n";
        }
      }

      let downloadLink = document.createElement("a");
      downloadLink.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(str);
      downloadLink.download = "corsisti_esami_" + city + ".csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  render() {
    const { city, loading, students, studentPayments, academicYear } = this.state;

    return (
      <>
        {loading && <MnemoLoading />}
        <section className="m-4">
          <Title>Sede Esami - Operazioni preliminari</Title>
          <Row>
            <Col md={12}>
              <MSelect
                onChange={this.setAcademicYear}
                id="academicYear"
                payload={this.PAYLOAD_YEAR}></MSelect>
            </Col>
          </Row>
          {(academicYear > 0) && (<>
            <Row>
              <Col md={6}>
                <MSelect
                  onChange={this.setIdExamSession}
                  id="idExamSession"
                  payload={this.PAYLOAD_EXAMSESSION[academicYear]}></MSelect>
              </Col>
              <Col md={6}>
                <MInput
                  id="mcity"
                  value={city}
                  label="Inserisci la città esami"
                  onChange={this.setCity}
                  infoText="Se vuoi tutte le citta scrivi 'TUTTE'"></MInput>
              </Col>
            </Row>
          </>)}
          <Button
            color="primary bg-dark"
            onClick={() => {
              this.loadStudents();
            }}>
            <span className="d-button-label">Elenco studenti</span>
          </Button>
          <Button
            className="ml-2"
            color="primary"
            outline
            onClick={() => {
              this.analyzeStudents();
            }}>
            <span className="d-button-label">Calcola tempo presenza</span>
          </Button>
          { /*
          <Button
            className="ml-2"
            color="primary"
            outline
            onClick={() => {
              this.verifyPaymentStudents();
            }}>
            <span className="d-button-label">
              Verifica pagamenti aggiuntivi
            </span>
          </Button>
          */}
          <Button
            className="ml-2"
            color="primary"
            outline
            onClick={() => {
              this.exportStudents();
            }}>
            <span className="d-button-label">Esporta</span>
          </Button>
          <hr />
          {students.length > 0 && (
            <>
              <h5>Studenti trovati {students.length}</h5>
              {(academicYear === 2023) ? (<>
                <table className="MTable table  table-striped table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      <th>Codice registrazione</th>
                      <th>Cognome</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Telefono</th>
                      <th>Classe di concorso</th>
                      <th>Sede esami</th>
                      <th>Minuti collegamento (totali)</th>
                      <th>Frequenza di collegamento (totali)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {studentPayments &&
                              studentPayments.hasOwnProperty(
                                student.registrationCode
                              ) ? (
                              <>{studentPayments[student.registrationCode]}</>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{student.registrationCode}</td>
                          <td>{student.surname}</td>
                          <td>{student.name}</td>
                          <td>{student.email}</td>
                          <td>{student.mobile}</td>
                          <td>{student.competitionclass}</td>
                          <td>{student.examSite}</td>
                          <td>{stringHelper.formatNumer(student.minutes)}</td>
                          <td>
                            {stringHelper.formatPercentage(student.percent)}{" "}
                            {student.percent < 0.7 && (
                              <span className="text-red">
                                LIMITE NON RAGGIUNTO
                              </span>
                            )}
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>) : (<>
                <table className="MTable table  table-striped table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      <th>Codice registrazione</th>
                      <th>Cognome</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Telefono</th>
                      <th>Classe di concorso</th>
                      <th>Sede esami</th>
                      <th>Minuti collegamento</th>
                      <th>Frequenza di collegamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, index) => {

                      const show = (index % 2 === 0);;
                      return (
                        <tr key={index}>
                          <td>{(show) && 
                            <>{Math.ceil((index + 1) / 2)}
                              <span title="aggiorna statistiche" width="30px"
                                className="action icon-link"
                                onClick={(e) => this.analyzeStudent(student)}>
                                <Icon color='primary' icon='it-chart-line' />
                              </span >
                            </>}
                          </td>
                          <td>
                            {studentPayments &&
                              studentPayments.hasOwnProperty(
                                student.registrationCode
                              ) ? (
                              <>{studentPayments[student.registrationCode]}</>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{(show) && student.registrationCode}</td>
                          <td>{(show) && student.surname}</td>
                          <td>{(show) && student.name}</td>
                          <td>{(show) && student.email}</td>
                          <td>{(show) && student.mobile}</td>
                          <td>{(show) && student.competitionclass}</td>
                          <td>{(show) && student.examSite}</td>
                          <td>{stringHelper.formatNumer(student.minutes)}<br />
                            <span>FASE {student.stage}</span>
                          </td>
                          <td>
                            {stringHelper.formatPercentage(student.percent)}{" "}
                            {student.percent < 0.7 && (
                              <span className="text-red">
                                LIMITE NON RAGGIUNTO
                              </span>
                            )}
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>)}

            </>)
          }

        </section>
      </>
    );
  }
}
