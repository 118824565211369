import React from 'react';

import { STRINGS, ROUTES } from '../../const';
import { NavLink } from 'react-router-dom';

import {
  Icon,
  LinkList,
  LinkListItem,
  Container,
  Row, Col,
} from 'design-react-kit';

import { SubscribeNewsletter } from 'components/subscribeNewsletter';
import CookieBot from 'react-cookiebot';
//import { getCurrentUser } from 'store/actions';

export class Footer extends React.Component {

  render() {

    //const user = getCurrentUser();

    return (
      <>
        <footer className="it-footer">
          <div className="it-footer-main">
            <Container tag="div">
              <section>
                <Row
                  className="clearfix"
                  tag="div"
                >
                  <Col
                    sm={12}
                    tag="div"
                    widths={[
                      'xs',
                      'sm',
                      'md',
                      'lg',
                      'xl'
                    ]}
                  >
                    <div className="it-brand-wrapper">
                      <NavLink
                        className=""
                        to={ROUTES.ROOT}
                      >
                        <img
                          className="d-none d-lg-block image-3 logo-footer-image"
                          src="/img/mnemo/logo_main.png"
                          alt={STRINGS.TITLE}
                        />
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </section>
              <section>
                <Row tag="div">
                  <Col
                    className="pb-2" lg={8} md={6} tag="div" >
                    <p>
                      Ente Accreditato M.I.U.R. che opera dal 2005 su tutto il territorio Nazionale ed Internazionale nel campo della
                      formazione e della ricerca scolastica, educativa ed Universitaria.
                    </p>
                    <p>Partita Iva : 02852670849 - Cod. Fisc: 02402740845 </p>
                  </Col>

                  {<Col className="pb-2" lg={4} md={6} tag="div">
                    <SubscribeNewsletter />
                  </Col>}

                </Row>
              </section>
              <hr className='hr-footer-separator'/>
              <section className="py-5">
                <Row tag="div">
                  <Col className="pb-3 px-5  text-footer" md={4} tag="div">
                    <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/milano`}>MILANO</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Piazza Firenze, 14 - Cap 20154<br />Tel <a href="tel:0234593068">0234593068</a> Fax 0234532652</p>
                  </Col>
                  <Col className="pb-3 px-5 text-footer" md={4} tag="div">
                  <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/roma`}>ROMA</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Via Properzio, 6 - Cap 00193<br />Tel <a href="tel:0670495308">0670495308</a> Fax 0677456950</p>
                  </Col>
                  <Col className="pb-3 px-5 text-footer" md={4} tag="div">
                  <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/firenze`}>FIRENZE</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Via di Novoli, 37 - Cap 50127<br />Tel <a href="tel:055485899">055485899</a> Fax 0554626203</p>
                  </Col>
                </Row>
              </section>
              <section>
                <Row tag="div">
                  <Col className="pb-3 px-5 text-footer" md={4} tag="div">
                  <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/bologna`}>BOLOGNA</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Viale della Repubblica - 3/A Cap 40127<br />Tel <a href="tel:0516335062">0516335062</a> Fax 0516336563</p>
                  </Col>
                  <Col className="pb-3 px-5 text-footer" md={4} tag="div">
                  <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/padova`}>PADOVA</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Via Andrea Costa 19 - Cap 35124 <br />Tel <a href="tel:049680148">049680148</a> Fax 0498675003</p>
                  </Col>
                  <Col className="pb-3 px-5 text-footer" md={4} tag="div">
                  <a className='' href={`${ROUTES.PAGE_CONTACT_INFO}/santa-margherita-di-belice`}>SANTA MARGHERITA DI BELICE (AG)</a>
                    <hr className='hr-footer' />
                    <p className='text-footer'>Largo Monfalcone - 15 Cap 92018<br />Tel <a href="tel:092533231">092533231</a> - <a href="tel:092531499">092531499</a> - <a href="tel:3296553069">3296553069</a> Fax 092532708</p>
                  </Col>
                </Row>
              </section>
              <hr className='hr-footer-separator'/>
              <section className="py-4">
                <Row tag="div">
                  <Col
                    className="pb-2"
                    md={3}
                    tag="div"
                    widths={[
                      'xs',
                      'sm',
                      'md',
                      'lg',
                      'xl'
                    ]}
                  >
                    <LinkList
                      className="footer-list clearfix"
                      tag="div"
                    >
                      <LinkListItem
                        href={ROUTES.PAGE_WHOARE}
                        tag="a"
                        title="Vai alla pagina: Chi siamo"
                      >Chi siamo</LinkListItem>                     
                      <LinkListItem
                        href={ROUTES.PAGE_SOLIDARITY}
                        tag="a"
                        title="Impegno e solidarietà"
                      >
                        Impegno e solidarietà
                      </LinkListItem>
                    </LinkList>

                  </Col>
                  <Col
                    className="pb-2" md={3} tag="div"
                    widths={[
                      'xs',
                      'sm',
                      'md',
                      'lg',
                      'xl'
                    ]}
                  >
                    <LinkList
                      className="footer-list clearfix"
                      tag="div"
                    >
                      <LinkListItem
                        href={ROUTES.PAGE_SUPPORT}
                        tag="a"
                        title="supporto tecnico"
                      >
                        Supporto tecnico
                      </LinkListItem>
                      <LinkListItem
                        href={ROUTES.PAGE_CONTACT}
                        tag="a"
                        title="contatti e sedi"
                      >
                        Contatti e sedi
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                    className="pb-2" md={3} sm={6} tag="div"
                    widths={[
                      'xs',
                      'sm',
                      'md',
                      'lg',
                      'xl'
                    ]}
                  >
                    <LinkList
                      className="footer-list clearfix"
                      tag="div"
                    >
                      <LinkListItem
                        href={ROUTES.QUALIFICATION_EVALUATION}
                        tag="a"
                        title="Vai alla pagina: Valutazione Titoli"
                      >Valutazione titoli</LinkListItem>
                      <LinkListItem
                        href={ROUTES.PAGE_RULE}
                        tag="a"
                        title="Regolamento"
                      >
                        Regolamento
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                    className="pb-2" md={3} tag="div"
                    widths={[
                      'xs',
                      'sm',
                      'md',
                      'lg',
                      'xl'
                    ]}
                  >
                    <ul className="list-inline text-left social">
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href="https://www.facebook.com/MnemosineFormazioneDocenti/"
                          target="_blank"
                          rel="noopener noreferrer" >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-facebook"
                            padding={false}
                            size="sm"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href="https://www.instagram.com/mnemosine_formazione_docenti/"
                          target="_blank"
                          rel="noopener noreferrer" >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-instagram"
                            padding={false}
                            size="sm"
                          />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          className="p-2 text-white"
                          href="https://www.linkedin.com/company/mnemosine"
                          target="_blank"
                          rel="noopener noreferrer" >
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-linkedin"
                            padding={false}
                            size="sm"
                          />
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </section>
            </Container>
          </div>

        {/*
          SAVERIO cookiebot 
             04.05.2023: remove cookiebot and add iubenda          
             15.05.2023: reinserted cookiebot
          */}
          <CookieBot domainGroupId={process.env.REACT_APP_COOKIEBOT_ID} />
        

          <div className="it-footer-small-prints clearfix">
            <Container tag="div">
              <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                <li className="list-inline-item hide">
                  <NavLink
                    to={ROUTES.INFORMATIVA}
                    title="Informativa Trattamento Dati Personali"
                  >
                    Informativa Trattamento Dati Personali
                  </NavLink>
                </li>
                <li className="list-inline-item hide">
                  <NavLink
                    to={ROUTES.PRIVACY_POLICY}
                    title="Privacy policy"
                  >
                    Privacy policy
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink
                    to={ROUTES.PRIVACY_POLICY_REV2022}
                    title="Privacy policy"
                  >
                    Privacy policy
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink
                    to={ROUTES.COOKIE_POLICY_REV2022}
                    title="Cookie policy"
                  >
                    Cookie policy
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink
                    to={ROUTES.INFORMATIVA_REV2022}
                    title="Informativa Trattamento Dati Personali"
                  >
                    Informativa Trattamento Dati Personali
                  </NavLink>
                </li>
                <li className="list-inline-item hide">
                  <NavLink
                    to={ROUTES.PRINCIPI_ETICI_REV2022}
                    title="Principi Etici"
                  >
                    Principi Etici
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink
                    to={ROUTES.WORK_WITH_US}
                    title="Lavora con noi"
                  >
                    Lavora con noi
                  </NavLink>
                </li>
              </ul>
            </Container>
          </div>
        </footer>        

        <div className="messangerOutLink">
          <a href='https://m.me/245574945460633' target='blank'><img src="/img/messanger.png" alt="Mnemosine Messanger" /></a>
        </div>
      </>);
  }
}