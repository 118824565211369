import React, { Component } from "react";

import { Row, Col } from "design-react-kit";
import { ROUTES } from "const";
// import { MnemoSlider } from "components/misc";
import { HomeCourses } from "./HomeCourses";

import "./HomeContent.css";
import { BlogHomeBox } from "components/blog";
import { ContactBox } from "components/layout";
import { Link } from "react-router-dom";
import { HomeNotices } from ".";

import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

// swiper slide
import { SliderHome } from "components/sliders/sliderHome/sliderHome";
import CorrispondenzaTitoli from "components/corrispondenza-titoli/CorrispondenzaTItoli";

// const SLIDES_DESKTOP = [
//   {
//     src: "/img/slideshow/desktop/0.webp",
//     alt: "Mnemosine iscrizione online vera",
//   },
//   {
//     src: "/img/slideshow/desktop/1.webp",
//     alt: "Mnemosine Corsi Singoli e Master",
//   },
//   {
//     src: "/img/slideshow/desktop/2.webp",
//     alt: "Mnemosine Sedi ed esami validi in tutta Italia",
//   },
//   {
//     src: "/img/slideshow/desktop/3.webp",
//     alt: "Mnemosine Leader dei Dottorati di Ricerca",
//   },
//   {
//     src: "/img/slideshow/desktop/4.webp",
//     alt: "Mnemosine certificazione di lingua inglese",
//   },
//   {
//     src: "/img/slideshow/desktop/5.webp",
//     alt: "Mnemosine certificazioni informatiche",
//   },
//   {
//     src: "/img/slideshow/desktop/6.webp",
//     alt: "Mnemosine riconosciuta dal ministero istruzione",
//   },
// ];

// const SLIDES_MOBILE = [
//   {
//     src: "/img/slideshow/mobile/0.webp",
//     alt: "Mnemosine iscrizione online vera",
//   },
//   {
//     src: "/img/slideshow/mobile/1.webp",
//     alt: "Mnemosine Corsi Singoli e Master",
//   },
//   {
//     src: "/img/slideshow/mobile/2.webp",
//     alt: "Mnemosine Sedi ed esami validi in tutta Italia",
//   },
//   {
//     src: "/img/slideshow/mobile/3.webp",
//     alt: "Mnemosine Leader dei Dottorati di Ricerca",
//   },
//   {
//     src: "/img/slideshow/mobile/4.webp",
//     alt: "Mnemosine certificazione di lingua inglese",
//   },
//   {
//     src: "/img/slideshow/mobile/5.webp",
//     alt: "Mnemosine certificazioni informatiche",
//   },
//   {
//     src: "/img/slideshow/mobile/5.webp",
//     alt: "Mnemosine riconosciuta dal ministero istruzione",
//   },
// ];

export class HomeContent extends Component {
  browseCourse = () => {
    window.location = ROUTES.COURSE_BROWSE;
  };

  render() {
    return (
      <>
        <SliderHome />

        {/* <section id="slider-section">
          <div className="d-none d-md-block">
            <MnemoSlider >
              {SLIDES_DESKTOP.map((slide, i) => (
                <div key={`slide-${i}`}>
                  <img
                    className="align-center w-100"
                    src={slide.src}
                    alt={slide.alt}
                  />
                </div>
              ))}
            </MnemoSlider>
          </div>
          <div className="d-block d-md-none">
            <MnemoSlider >
              {SLIDES_MOBILE.map((slide, i) => (
                <div key={`slide-${i}`}>
                  <img
                    className="align-center w-100"
                    src={slide.src}
                    alt={slide.alt}
                    onClick={this.browseCourse}
                  />
                </div>
              ))}
            </MnemoSlider>
          </div>
        </section> */}

        {/* <section>
          <a href={ROUTES.QUALIFICATION_MAP} className='link-pointer' >
            <div className="section section-muted text-center mt-4 py-2">
              <div className="d-none d-md-block" >
                <h2>Corrispondenza Titoli - Classi di concorso - Master consigliati</h2>
              </div>
              <div className="d-block d-md-none">
                <h3>Corrispondenza Titoli - Classi di concorso - Master consigliati</h3>
              </div>
            </div>
          </a>
        </section> */}

        <CorrispondenzaTitoli href={ROUTES.QUALIFICATION_MAP} />

        <HomeCourses></HomeCourses>

        <section id="notices-section">
          <HomeNotices />
        </section>

        <section id="badge-section">
          <Row className="mt-1">
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <img
                className="icon-home"
                src="/img/icon/student.png"
                alt="Mnemosine"
              />
              <p className="text-green">
                Da oltre 16 anni nel settore della Formazione.
                <br />
                Oltre 200.000 studenti soddisfatti
              </p>
            </Col>
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <img
                className="icon-home"
                src="/img/icon/italy.png"
                alt="Mnemosine"
              />
              <p className="text-green">
                Oltre 100 sedi
                <br /> in tutta Italia.
              </p>
            </Col>
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <img
                className="icon-home"
                src="/img/icon/nopaper.png"
                alt="Mnemosine"
              />
              <p className="text-green">
                Iscrizioni 100% online. Nessun invio cartaceo
              </p>
            </Col>
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <img
                className="icon-home"
                src="/img/icon/geo.png"
                alt="Mnemosine"
              />
              <p className="box-text-center text-green">
                Prove finali a distanza in tutti Comuni d'Italia e in presenza
                in tutte le 100 sedi
              </p>
            </Col>
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <br />
              <img
                className="icon-home"
                src="/img/mim.png"
                alt="Mnemosine"
              />
              <p className="text-green"><br />Ente accreditato</p>
            </Col>
            <Col xs={6} sm={4} md={2} className="box-text-center">
              <img
                className="icon-home"
                src="/img/icon/carta_docente.png"
                alt="carta_del_docente"
              />
              <p className="text-green">Puoi spendere con noi il tuo buono</p>
            </Col>
          </Row>
        </section>

        <section className="sedi-map-section">
          <div className="sedi-map-image">
            <img
              src="/img/mnemo/sedi-map.png"
              alt="oltre 100 sedi in tutta Italia"
            />
            <div className="sedi-map-image-text">
              <img
                className="sedi-map-image-logo"
                src="/img/mnemo/logo_main.png"
                alt="oltre 100 sedi in tutta Italia"
              />
              <p className="sedi-map-image-text-title display-3">
                Oltre 100 sedi in tutta Italia
              </p>
            </div>
          </div>
          <div className="sedi-map-content">
            <p className="sedi-map-content-title">

              Esami scritti e orali in più di 100 città italiane. Scegli la tua
              sede senza alcun vincolo o costo aggiuntivo.
            </p>
            <p className="sedi-map-content-text">
              E' prevista l’apertura di ulteriori sedi, anche all’estero, al
              raggiungimento del numero minimo di 20 iscritti. <br />
              Elenco delle sedi nel dettaglio del corso.
            </p>
            <ButtonPrimary color="yellow" label="scopri le sedi" href="/sedi-esami" />
          </div>
        </section>

        <section id="company-section">
          <Row>
            <Col lg={7} md={6}></Col>
            <Col lg={5} md={6} className="p-0">
              <div className="px-5 pb-2 white-bg m-0">
                <img
                  className="align-center w-75"
                  src="/img/mnemo/logo_simple.webp"
                  alt="Mnemosine"
                />
                <div className="m-0">
                  Quando agli inizi del Duemila abbiamo progettato e
                  successivamente costituito l'Associazione Mnemosine, il nostro
                  primo obiettivo è stato quello di offrire validi e utili
                  strumenti a chi si proiettava (e si proietta) come educatore
                  nel mondo scolastico.
                  <br />
                  Oggi l'Associazione Mnemosine è un{" "}
                  <b>Ente Accreditato M.I.U.R.</b> che opera su tutto il
                  territorio <b>Nazionale</b> e <b>Internazionale</b> nel campo
                  della formazione e della ricerca scolastica, educativa e
                  universitaria. La nostra Associazione vanta prestigiose sedi
                  di sua proprietà in diverse regioni oltre ad avere 100 sedi di
                  esami in tutta Italia, 25 sedi periferiche, 10 sedi d'esame
                  all'estero e più di 50 accordi con università europee e
                  internazionali. Dal 2005 oltre 200.000 studenti ci hanno già
                  scelto, perché con Mnemosine la tua carriera è al sicuro!
                  <Link
                    to={ROUTES.PAGE_WHOARE}
                    className="m-5 float-right btn btn-primary bg-dark"
                  >
                    La nostra storia
                  </Link>
                  <div className="clearfix"></div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section id="news-section">
          <BlogHomeBox />
        </section>

        <section id="other-section">
          <ContactBox />
        </section>

        <section id="cinquepermille">
          {/* <a
            href="https://www.leonisicani.it/dona-il-tuo-5x1000/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/leoni-sicani/cinquepermille.jpeg"
              alt="dona il tuo ciqnue per mille ai leoni sicani"
            />
          </a> */}
          <a href="/associazione-a-s-d-leoni-sicani-onlus">
            <img
              src="/img/leoni-sicani/leoni-sicani-banner.jpg"
              alt="dona il tuo ciqnue per mille ai leoni sicani"
            />
          </a>
        </section>
      </>
    );
  }
}
