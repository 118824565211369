import React, { Component } from "react";
import QrCodeReader from "./QrCodeReader";

export class StewardVerifyIdentity extends Component {
  
  constructor(props) {
    super(props);

    this.state = {};
  } //constructor

  componentDidMount() {
  } //componentDidMount

  //--- ---

  render() {

    return (
      <>
        <section className="stewardPage">
          <QrCodeReader />
        </section>
      </>
    );
  }
}
