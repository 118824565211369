import { ROUTES } from "const";
export const COURSES_LIST = [
  /*{
    link: `/corso-online/CSU24CFU`,
    image: `/img/courses/new/24cfu.jpg`,
    title: "Corsi Singoli 24CFU",
    subTitle: "24CFU Corsi per l'insegnamento",
    highlights: "Iscrizioni chiuse",
  },
  {
    link: `/corso-online/MUMC24`,
    image: `/img/courses/new/master-24cfu2.jpeg`,
    title: "Master 24CFU",
    subTitle: "Comprensivo dei 24CFU per l'insegnamento",
    highlights: "Iscrizioni chiuse",
  },*/
  {
    link: `${ROUTES.COURSE_BROWSE}/UCSU`,
    image: `/img/courses/new/ucsu.jpeg`,
    title: "Corsi Singoli  Universitari",
    subTitle: "",
    highlights: "",
  },
  {
    link: ROUTES.COURSE_SSD_BROWSE,
    image: `/img/courses/new/mr.jpg`,
    title: "Corsi Singoli e Master",
    subTitle: "A11, A18, A28, A21, A26, A46... <br/><b>Nuovi Master: A26, A27, A28</b>",
    highlights: "Da €440",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/BU-I`,
    image: `/img/courses/new/bu-i.jpg`,
    title: "Diplomi di perfezionamento biennale",
    // subTitle: "1500 ore e 60 CFU per ogni annualità",
    highlights:
      "Docenti di ruolo: punti 5 nei trasferimenti <br/>Precari: punti 2 (oltre ai 3 corsi annuali)",
  },
  {
    link: `/corso-online-mnemosine/RCPFU5CFU`,
    image: `/img/courses/new/5cfu.png`,
    title: "Percorso di Formazione 5 CFU",
    subTitle: "Per docenti vincitori di Concorso - Esami in PRESENZA",
    highlights: "",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MU`,
    image: `/img/courses/new/mu.jpg`,
    title: "Master di I livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da €440",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/UCLIL`,
    image: `/img/courses/new/clil.jpg`,
    title: "Perfezionamento CLIL",
    subTitle: "1500 ore e 60 CFU",
    highlights: "A soli € 498",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MDU`,
    image: `/img/courses/new/mdu.jpg`,
    title: "Master di II livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "",
  },
  {
    link: `${ROUTES.PAGE_PERFEZIONAMENTOPDUPLU}/`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ATA`,
    image: `/img/courses/new/ata.jpg`,
    title: "Dattilografia e ICDL IT-Security",
  },

  /*{
    link: `${ROUTES.COURSE_BROWSE}/PLU`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento post laurea",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/PDU`,
    image: `/img/courses/new/pd.jpg`,
    title: "Diplomi di perfezionamento post diploma",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  },*/

  /* {
     link: `${ROUTES.PAGE_CORSIDILAUREA}`,
     image: `/img/courses/new/corsi-di-laurea.jpg`,
     title: "Corsi di Laurea",
     subTitle:
       "<br/>Lauree VERE per un futuro lavorativo REALE: da Scienze Infermieristiche a Medicina!",
   },*/
  {
    link: ROUTES.FORM_60CFU,
    image: `/img/courses/new/30_60cfu.jpg`,
    title: "Corsi Abilitanti 30, 36 e 60 CFU",
    subTitle: "",
    highlights: "",
  },
  {
    link: ROUTES.ENROLL_TFA_IX_CICLO + '/TFA_IX_CICLO_ORDINARIO',
    image: `/img/courses/banner_u24cfu.webp`,
    title: "TFA Sostegno Ordinario 2024",
    subTitle: '',
    highlights: "",
  },
  /*{
    link: ROUTES.ENROLL_TFA_IX_CICLO + '/TFA_IX_CICLO_RISERVATO',
    image: `/img/courses/banner_u24cfu.webp`,
    title: "TFA Sostegno 2024",
    subTitle: 'Solo riservisti',
    highlights: "(con 3 anni di servizio su sostegno)",
  },*/
  {
    link: "/corso-online-mnemosine/TFA_IX_CICLO_SOPRANNUMERARI_IDONEI",
    image: `/img/courses/banner_tfa_01.jpg`,
    title: "TFA Sostegno 2024",
    subTitle: 'Solo per soprannumerari',
  },
  /*{
    image: `/img/courses/new/nuovi_master.jpg`,
    title: "A breve Master per:",
    subTitle: "&checkmark; A-26 Matematica<br/> &checkmark; A-27 Matematica e Fisica<br/> &checkmark; A-28 Matematica e Scienze<br/><b>Conseguibili da Febbraio 2024 (entro l'aggiornamento Grad. Istituto)</b>",
    highlights: "",
  },*/
  {
    link: `${ROUTES.COURSE_BROWSE}/INFO`,
    image: `/img/courses/new/info.jpg`,
    title: "",
    subTitle:'<h4 class="programme-card__title"><h4 class="programme-card__title">Certificazioni Informatiche</h4>',
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ENG`,
    image: `/img/courses/new/eng.jpg`,
    title: "Inglese B2-C1",
    subTitle: "English Speaking Board (ESB)",
    highlights: "Da € 148",
  },

  // {
  //   link: "https://www.asuniver.it/corso-di-sostegno-allestero/",
  //   image: `/img/courses/new/sostegno_spagna.jpg`,
  //   title: "Corso di Sostegno all’estero",
  // },
];

export const COURSE_SECONDARY_LIST = [
  /*{
    link: `${ROUTES.COURSE_MUPA_BROWSE}`,
    image: `/img/courses/new/mupabox.jpg`,
    title: "Master per Pubblica Amministrazione e Forze Armate",
    subTitle: "",
    highlights: "",
  },*/
  {
    link: `${ROUTES.PAGE_MASTERMUPAMSLU}/`,
    image: `/img/courses/new/mupabox.jpg`,
    title: "Master per",
    subTitle: "",
    highlights: " - Pubblica Amministrazione <br/>- Forze Armate",
  },
  {
    link: `/corso-online/ABA`,
    image: `/img/courses/new/aba-v2.jpg`,
    title: "ABA",
    subTitle: "Applied Behavior Analysis",
  },
  /*{
    link: `/corso-online/TDIDEM22`,
    image: `/img/courses/new/didattica-delle-emozioni.jpg`,
    title: "Didattica delle emozioni",
    subTitle: `Il metodo dell'educazione emotiva a scuola`,
  },*/
  {
    link: `/corsi-mnemosine/FC`,
    image: `/img/courses/new/formazione-continua-v2.jpg`,
    title: "Formazione continua",
    subTitle: "Valorizza il buono scuola con i nostri corsi",
  },
];
